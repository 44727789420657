.sidebar-container {
	position: fixed;
	left: 0;
	display: block;
	width: var(--sidebar-width);
	background: #f0f0f0;
	border-right: 1px solid #e9e9e9;
	box-shadow: 0 -5px 8px 1px #e9e9e9;
	z-index: 100;
	transition: left 0.3s;
}

.sidebar-container-show {
	left: 0 !important;
	box-shadow: 0 -5px 8px 1px #e9e9e9 !important;
}

.sidebar-container-hide {
	left: calc(var(--sidebar-width) * -1) !important;
	box-shadow: none !important;
}

@media (max-width: 767px) {
	.sidebar-container {
		left: calc(var(--sidebar-width) * -1);
		box-shadow: none;
	}
}
